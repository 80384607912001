<template>
    <div class="root_banner_mobile">
        <div class="banner-home">
            <carousel :items-to-show="1" :wrap-around="true" :autoplay="6000">
                <slide :key="index" v-for="(item, index) in listBanner">
                    <a :href="linkf8bet" target="_blank">
                        <img :src="item.img" alt="">
                    </a>
                </slide>
                <!-- <template #addons>
                    <Navigation />
                    <Pagination />
                </template> -->
            </carousel>
        </div>
        <div class="textrun_mobile">
            <div class="marquee_header">
                <div class="flex_marquee">
                    <div class="title_marquee">
                        <img src="../assets/images/loa.png" alt="">
                    </div>
                    <div class="marquee_content">
                        <Vue3Marquee :pause-on-click="true" duration="100">
                            <div class="mqtext_mb">
                                <ul class="list_marquee">
                                    <li><a :href="linkf8bet" target="_blank">🏆 F8game.live thông báo: đã phát thưởng thành công quà ngẫu nhiên đến 1000 khách hàng may mắn vào mỗi thứ 7 và chủ nhật 🧧🧧</a></li>
                                    <li><a :href="linkf8bet" target="_blank">🏆 Thưởng nạp đầu 100% 1 vòng cược lúc 13 giờ 00  đến 15 giờ 00 ❤️❤️</a></li>
                                    <li><a :href="linkf8bet" target="_blank">🏆 Xem livestream Idol nhận thêm 68k lúc 19 giờ 00 đến 23  giờ 00</a></li>
                                    <li><a :href="linkf8bet" target="_blank">🏆Tặng không giới hạn 0.6% mỗi lần nạp, tặng 5% tiền nạp chủ nhật vàng lúc 23 giờ 00 - 1 giờ 00🧧🧧</a></li>
                                    <li><a :href="linkf8bet" target="_blank">🏆Các khuyến mãi lớn sẽ được 78WIN phát đều đặn hàng tháng vào các ngày 05-15-25 hàng tháng: Khuyến  F8game.live kính chúc Quý hội viên nhiều sức khỏe, hạnh phúc. Đặc biệt ngập tràn may mắn cược đâu thắng đó, cược to thắng lớn. ⭐️</a></li>
                                </ul>
                            </div>
                        </Vue3Marquee>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu_mobile_edit">
            <div class="wp_mn_mobile_edit">
                <div class="col-md-3 col-sm-3 col-xs-3 leftmenu_mobile">
                    <div class="wp_lf_menumobile">
                        <img src="../assets/images/images/mobile_games/menu.png" alt="" class="showmenu_mobile active">
                        <img src="../assets/images/images/mobile_games/close.png" alt="" class="hidemenu_mobile">
                        <div class="menu_detail_mobile">
                            <ul>
                                <li>
                                    <router-link class="nav-item" to='/'>Trang chủ</router-link>
                                </li>
                                <li>
                                    <router-link class="nav-item" to='/live'>Live game</router-link>
                                </li>
                                <li>
                                    <router-link class="nav-item" to='/idol-live'>Live tâm sự</router-link>
                                </li>
                                <li>
                                    <router-link class="nav-item" to='/video-propose'>Live show 18+</router-link>
                                </li>
                                <li>
                                    <router-link class="nav-item" to='/asian'>Asian</router-link>
                                </li>
                                <li>
                                    <router-link class="nav-item" to='/video'>Video</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9 col-sm-9 col-xs-9 rightmenu_mobile">
                    <div class="wp_rg_menumobile">
                        <ul>
                            <li><a target="_blank" :href="linkf8bet"><img src="../assets/images/images/mobile_games/choithu.png" alt=""></a></li>
                            <li><a target="_blank" :href="linkf8bet"><img src="../assets/images/images/mobile_games/quantam.png" alt=""></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
// import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

$( document ).ready(function() {
    $(document).on('click', '.showmenu_mobile', function() {
        $('.hidemenu_mobile').addClass('active');
        $(this).removeClass('active');
        $('.menu_detail_mobile').addClass('active');
    });
    $(document).on('click', '.hidemenu_mobile', function() {
        $('.showmenu_mobile').addClass('active');
        $(this).removeClass('active');
        $('.menu_detail_mobile').removeClass('active');
    });
    $(document).on('click', '.menu_detail_mobile ul li a', function() {
        $('.showmenu_mobile').addClass('active');
        $('.hidemenu_mobile').removeClass('active');
        $('.menu_detail_mobile').removeClass('active');
    });
});
export default {
    name: 'Banner_adv',
    data () {
        return {
            linkf8bet: '',
            token: window.localStorage.getItem('token'),
            listBanner: []
        }
    },
    components: {
        Carousel,
        Slide,
        // Pagination,
        // Navigation,
    },
    created () {
        this.getInfoLink()
        this.GetListBanner()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkf8bet = response.data.data.list_data.banner
            })
        },
        GetListBanner () {
            this.listBanner = [
                { img: require('@/assets/images/banner/1.jpg')},
                { img: require('@/assets/images/banner/2.jpg')},
                { img: require('@/assets/images/banner/3.jpg')},
                //{ img: require('@/assets/images/banner/4.jpg')},
                //{ img: require('@/assets/images/banner/5.jpg')},
                //{ img: require('@/assets/images/banner/6.jpg')},
                // { img: require('@/assets/images/banner/7.jpg')},
                // { img: require('@/assets/images/banner/8.jpg')},
                // { img: require('@/assets/images/banner/9.jpg')},
                // { img: require('@/assets/images/banner/10.jpg')},
                // { img: require('@/assets/images/banner/11.jpg')},
                // { img: require('@/assets/images/banner/12.jpg')},
                // { img: require('@/assets/images/banner/13.jpg')},
                // { img: require('@/assets/images/banner/14.jpg')},
                // { img: require('@/assets/images/banner/15.jpg')},
            ]
        },
    }
}
</script>
<style>
</style>
