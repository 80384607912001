<template>
    <div class="root_header_page">
        <div class="banner_star_page">
            <a :href="linkf8bet" target="_blank">
                <img src="../assets/images/bnd.gif" alt="">
            </a>
        </div>
        <header id="header" :class="'header has-sticky sticky-shrink ' + classHeader">
            <div class="container ner_responesize">
                <div class="menu_desktop">
                    <div class="row">
                        <div class="col-md-7 col-sm-8 col-xs-9 left-logo">
                            <div id="logo" class="logo_live">
                                <router-link v-if="$route.path != '/'" class="nav-item" to='/' title="">
                                    <img src="../assets/images/logo.png" class="header_logo header-logo" alt="">
                                </router-link>
                                <a v-else class="nav-item" href='/' title="">
                                    <img src="../assets/images/logo.png" class="header_logo header-logo" alt="">
                                </a>
                                <ul class="header-nav">
                                    <li>
                                        <router-link class="nav-item" to='/' v-if="$route.path != '/'">Trang chủ</router-link>
                                        <a class="nav-item router-link-exact-active" href='/' v-else>Trang chủ</a>
                                    </li>
                                    <li>
                                        <router-link v-if="token != null" class="nav-item" to='/live'>Chơi game <img src="../assets/images/online.gif" alt="" class="img_online_header"></router-link>
                                        <a href="javascript:;" @click="viewmodal_lg" v-else>Chơi game</a>
                                    </li>
                                    <!-- <li>
                                        <router-link v-if="token != null" class="nav-item" to='/idol-show'>Show <span class="span18">18+</span></router-link>
                                        <a href="javascript:;" @click="viewmodal_lg" v-else>Show <span class="span18">18+</span></a>
                                    </li> -->
                                    <li>
                                        <router-link v-if="token != null" class="nav-item" to='/video-propose'>Live show 18+</router-link>
                                        <a href="javascript:;" @click="viewmodal_lg" v-else>Live show 18+</a>
                                    </li>
                                    <li>
                                        <router-link v-if="token != null" class="nav-item" to='/asian'>Asian</router-link>
                                        <a href="javascript:;" @click="viewmodal_lg" v-else>Asian</a>
                                    </li>
                                    <!-- <li>
                                        <router-link class="nav-item" to='/video'>Video</router-link>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-5 col-sm-4 col-xs-3 right-menu">
                            <div class="wp-menu">
                                <ul class="header-register-login" v-if="token == null">
                                    <li class="btnlogin_header"><a @click="viewmodal_rg" target="_blank">Đăng ký</a></li>
                                    <li class="btnlogin_header"><a @click="viewmodal_lg" target="_blank">Đăng nhập</a></li>
                                    <li class="downapp_header"><a href="https://f8game.click/img/app.apk" download><img src="../assets/images/download.gif" alt="" class="img_down"></a></li>
                                    <li class="downapp_header downapp_header1"><a href="https://testflight.apple.com/join/tvpJzFOW" target="_blank"><img src="../assets/images/dtios.gif" alt="" class="img_down"></a></li>
                                </ul>
                                <ul class="info_user" v-else>
                                    <li class="downapp_header downapp_header1">
                                        <a href="https://testflight.apple.com/join/tvpJzFOW" target="_blank"><img src="../assets/images/dtios.gif" alt="" class="img_down"></a>
                                    </li>
                                    <li class="downapp_header">
                                        <a href="https://f8game.click/img/app.apk" download>
                                            <img src="../assets/images/download.gif" alt="" class="img_down">
                                        </a>
                                    </li>
                                    <li class="notifyHead">
                                        <div class="clickshow">
                                            <img src="../assets/images/noti.png" alt="" class="shownoti">
                                            <span class="nunbernoti" v-if="numberNoti != 0">{{ numberNoti }}</span>
                                        </div>
                                        <div class="listnotiHeader">
                                            <div class="scrollNoti">
                                                <ul>
                                                    <li :class="{notview: item.view_web==1}" v-for="(item, index) in listNoti" :key="index" :data_id="item.id" @click="viewNoti">
                                                        <router-link to="/notification" :data_id="item.id">
                                                            <p v-if="item['arrinfo'][0].action == 'register_approve'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã được duyệt làm idol. Tên đăng nhập <b :data_id="item.id">{{ item['arrinfo'][0].username }}</b>, mật khẩu <b :data_id="item.id">{{ item['arrinfo'][0].password }}</b> <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                            <p v-else-if="item['arrinfo'][0].action == 'register_cancel'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> không được duyệt làm idol. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                            <p v-else-if="item['arrinfo'][0].action == 'qc_create'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã bị phạt lần thứ <b :data_id="item.id">{{ item['arrinfo'][0].level }}</b> mức phạt <b :data_id="item.id">{{ item['arrinfo'][0].value }}%</b>. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                            <p v-else-if="item['arrinfo'][0].action == 'qc_delete'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã được xóa phạt. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                            <p v-else-if="item['arrinfo'][0].action == 'update_streamkey'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã được cấp phát luồng livestream. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li v-if="type==1" class="streamheader imgcss">
                                        <span @click="showModals = true" v-if="live==0"><font-awesome-icon icon="fa-solid fa-video" /> Live stream</span>
                                        <span @click="LiveStreamOff" v-else><font-awesome-icon icon="fa-solid fa-video" /> Tắt live</span>
                                    </li>
                                    <li class="pad_header imgcss">
                                        <a href="javascript:;">
                                            <img :src="link_web + picture" alt="" v-if="picture !== null"> 
                                            <img src="../assets/images/user.png" alt="" v-else> 
                                            {{ fullname }}
                                        </a>
                                        <ul>
                                            <li>
                                                <router-link v-bind:to="{ name: 'Info_nm', params: { id: iduser } }">
                                                    Tài khoản
                                                </router-link>
                                            </li>
                                            <li @click="showModal_bgr = true" v-if="type==1">Tải ảnh video</li>
                                            <li @click="showmodal_changepass = true">Đổi mật khẩu</li>
                                            <li >
                                                <router-link to="/support" class="menusupport_hd">Hỗ trợ <span class="number_support" v-if="numberspo.length > 0">{{ numberspo.length }}</span></router-link>
                                            </li>
                                            <li>
                                                <router-link v-bind:to="{ name: 'Gift_nm', params: { id: iduser } }">
                                                    Lịch phát quà
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link to="/list-register">
                                                    Danh sách đăng ký idol
                                                </router-link>
                                            </li>
                                            <li v-if="type==1">
                                                <router-link to="/payroll">
                                                    Bảng lương
                                                </router-link>
                                            </li>
                                            <!-- <li>
                                                <router-link v-bind:to="{ name: 'Chat_nm', params: { idUser: iduser, idIdol: 0 } }">
                                                    Chat
                                                </router-link>
                                            </li> -->
                                            <!-- <li @click="showModalCashin">Nạp tiền</li> -->
                                            <li @click="logout">Đăng xuất</li>
                                        </ul>
                                        <span class="number_support" v-if="numberspo.length > 0">{{ numberspo.length }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- modal login -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModal" @click="showModal = false"></div>
                    <div class="modal" v-if="showModal">
                        <div class="wp-modal-login">
                            <div class="header-modal">
                                <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <Login_f8 @showMd="showModalrg" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal login -->
                <!-- modal register -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModal_dk" @click="showModal_dk = false"></div>
                    <div class="modal" v-if="showModal_dk">
                        <div class="wp-modal-register">
                            <div class="header-modal">
                                <button class="close-modal_login" @click="showModal_dk = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <Register_f8 @showMd="showModallg"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal register -->
                <!-- modal live -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModals" @click="showModals = false"></div>
                    <div class="modal" v-if="showModals">
                        <div class="wp-modal-login">
                            <div class="header-modal">
                                <ul class="list_menu_modal">
                                    <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                    <li class="active">Live stream</li>
                                </ul>
                                <button class="close-modal_login" @click="showModals = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <form action="" method="post" id="frm_livestream" @submit="LiveStreamOn">
                                        <!-- <input type="text" v-model="message" class="title_live" placeholder="Tin nhắn ghim"> -->
                                        <!-- <p class="title_pin_mes">Tin nhắn ghim</p>
                                        <ckeditor :editor="editor" :config="editorConfig" v-model="message"></ckeditor> -->
                                        <input type="text" v-model="title" class="title_live" placeholder="Tiêu đề">
                                        <ul>
                                            <li>
                                                <input type="radio" class="stream_number" id="stream_number_1" value="1" v-model="stream" checked>
                                                <label for="stream_number_1">Luồng 1</label>
                                                <div class="check"></div>
                                            </li>
                                            <li>
                                                <input type="radio" class="stream_number" id="stream_number_2" value="2" v-model="stream">
                                                <label for="stream_number_2">Luồng 2</label>
                                                <div class="check"><div class="inside"></div></div>
                                            </li>
                                        </ul>
                                        <button type="submit">Bắt đầu live</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal live -->
                <!-- modal uploads img video -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModal_bgr" @click="showModal_bgr = false"></div>
                    <div class="modal" v-if="showModal_bgr">
                        <div class="wp-modal-login">
                            <div class="header-modal">
                                <ul class="list_menu_modal">
                                    <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                    <li class="active">Tải ảnh video</li>
                                </ul>
                                <button class="close-modal_login" @click="showModal_bgr = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <form action="" method="post" id="frm_uploads_img" @submit="ChangePicture">
                                        <input type="file" required name="change_picture" class="change_picture" id="change_picture" />
                                        <label class="change_picture" for="change_picture">
                                            <font-awesome-icon icon="fa-solid fa-image" />
                                            <span>Chọn ảnh</span>
                                        </label>
                                        <button type="submit">Tải lên</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal uploads img video -->
                <!-- modal changepass -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showmodal_changepass" @click="showmodal_changepass = false"></div>
                    <div class="modal" v-if="showmodal_changepass">
                        <div class="wp-modal-login">
                            <div class="header-modal">
                                <ul class="list_menu_modal">
                                    <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                    <li class="active">Đổi mật khẩu</li>
                                </ul>
                                <button class="close-modal_login" @click="reLoadPage"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <form action="" method="post" id="frm_changepass" @submit="Changepass">
                                        <input type="password" v-model="password_old" placeholder="Mật khẩu cũ">
                                        <input type="password" v-model="password_new" placeholder="Mật khẩu mới">
                                        <input type="password" v-model="password_confirm_change" placeholder="Xác nhận mật khẩu">
                                        <button type="submit">Gửi</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal changepass -->
                <div :class="'menu_mobile ' + active">
                    <div class="info_mobile">
                        <ul class="btn_login_register_mobile" v-if="token == null">
                            <!-- <li class="downapp_header">
                                <a href="https://f8game.click/img/app.apk" download>
                                    <img src="../assets/images/download.gif" alt="" class="img_down">
                                </a>
                            </li> -->
                            <li v-if="token == null" class="btnlogin_header"><a @click="viewmodal_lg">Đăng nhập</a></li>
                            <li v-if="token == null"><a @click="viewmodal_rg">Đăng ký</a></li>
                        </ul>
                        <ul class="info_user" v-else>
                            <!-- <li class="downapp_header">
                                <a href="https://f8game.click/img/app.apk" download>
                                    <img src="../assets/images/download.gif" alt="" class="img_down">
                                </a>
                            </li> -->
                            <li class="notifyHead">
                                <div class="clickshow">
                                    <img src="../assets/images/noti.png" alt="" class="shownoti">
                                    <span class="nunbernoti" v-if="numberNoti != 0">{{ numberNoti }}</span>
                                </div>
                                <div class="listnotiHeader">
                                    <div class="scrollNoti">
                                        <ul>
                                            <li :class="{notview: item.view_web==1}" v-for="(item, index) in listNoti" :key="index" :data_id="item.id" @click="viewNoti">
                                                <router-link to="/notification" :data_id="item.id">
                                                    <p v-if="item['arrinfo'][0].action == 'register_approve'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã được duyệt làm idol. Tên đăng nhập <b :data_id="item.id">{{ item['arrinfo'][0].username }}</b>, mật khẩu <b :data_id="item.id">{{ item['arrinfo'][0].password }}</b> <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                    <p v-else-if="item['arrinfo'][0].action == 'register_cancel'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> không được duyệt làm idol. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                    <p v-else-if="item['arrinfo'][0].action == 'qc_create'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã bị phạt lần thứ <b :data_id="item.id">{{ item['arrinfo'][0].level }}</b> mức phạt <b :data_id="item.id">{{ item['arrinfo'][0].value }}%</b>. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                    <p v-else-if="item['arrinfo'][0].action == 'qc_delete'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã được xóa phạt. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                    <p v-else-if="item['arrinfo'][0].action == 'update_streamkey'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã được cấp phát luồng livestream. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li v-if="type==1" class="streamheader imgcss">
                                <span @click="showModals = true" v-if="live==0"><font-awesome-icon icon="fa-solid fa-video" /> Live stream</span>
                                <span @click="LiveStreamOff" v-else><font-awesome-icon icon="fa-solid fa-video" /> Tắt live</span>
                            </li>
                            <li class="pad_header imgcss">
                                <a href="javascript:;">
                                    <img :src="link_web + picture" alt="" v-if="picture !== null"> 
                                    <img src="../assets/images/user.png" alt="" v-else> 
                                    {{ fullname }}
                                </a>
                                <ul>
                                    <li>
                                        <router-link v-bind:to="{ name: 'Info_nm', params: { id: iduser } }">
                                            Tài khoản
                                        </router-link>
                                    </li>
                                    <li @click="showModal_bgr = true" v-if="type==1">Tải ảnh video</li>
                                    <li @click="showmodal_changepass = true">Đổi mật khẩu</li>
                                    <li>
                                        <router-link to="/support" class="menusupport_hd">Hỗ trợ <span class="number_support" v-if="numberspo.length > 0">{{ numberspo.length }}</span></router-link>
                                    </li>
                                    <li>
                                        <router-link v-bind:to="{ name: 'Gift_nm', params: { id: iduser } }">
                                            Lịch phát quà
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/list-register">
                                            Danh sách đăng ký idol
                                        </router-link>
                                    </li>
                                    <li v-if="type==1">
                                        <router-link to="/payroll">
                                            Bảng lương
                                        </router-link>
                                    </li>
                                    <li @click="logout">Đăng xuất</li>
                                </ul>
                                <span class="number_support" v-if="numberspo.length > 0">{{ numberspo.length }}</span>
                            </li>
                        </ul>
                    </div>
                    <p @click="clickRemove">
                        <router-link v-if="$route.path != '/'" class="nav-item" to='/' title="">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="">
                        </router-link>
                        <a v-else class="nav-item" href='/' title="">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="">
                        </a>
                    </p>
                    <div class="menu-mb">
                        <ul>
                            <li @click="clickRemove">
                                <router-link class="nav-item" to='/' v-if="$route.path != '/'">Trang chủ</router-link>
                                <a class="nav-item" href='/' v-else>Trang chủ</a>
                            </li>
                            <li @click="clickRemove">
                                <router-link class="nav-item" to='/live'>Chơi game</router-link>
                            </li>
                            <li @click="clickRemove">
                                <router-link class="nav-item" to='/idol-show'>Show 18+</router-link>
                            </li>
                            <!-- <li @click="clickRemove"><router-link class="nav-item" to='/ban-ca'>Bắn cá</router-link></li>
                            <li @click="clickRemove"><router-link class="nav-item" to='/no-hu'>Nỗ hũ</router-link></li>
                            <li @click="clickRemove"><router-link class="nav-item" to='/game'>Game</router-link></li> -->
                            <li v-if="token == null"><a @click="viewmodal_lg" target="_blank">Đăng nhập</a></li>
                            <li v-if="token == null"><a :href="linkf8bet" target="_blank">Đăng ký</a></li>
                        </ul>
                    </div>
                </div>
                <!-- icon right -->
                <div class="social_icon mobile_hide_icon">
                    <ul>
                        <!-- <li v-if="token == null"><a href="javascript:;" @click="FormLoginShow"><img src="../assets/images/face.png" alt="" title="support"></a></li>
                        <li v-else><router-link to='/social'><img src="../assets/images/face.png" alt="" title="Social"></router-link></li> -->
                        <li v-if="token == null"><a href="javascript:;" @click="FormLoginShow"><img src="../assets/images/help.gif" alt="" title="support"></a></li>
                        <li v-else><router-link to='/support'><img src="../assets/images/help.gif" alt="" title="support"></router-link></li>
                        <li><a :href="zalo" target="_blank"><img src="../assets/images/zl.gif" alt="" title="zalo"></a></li>
                        <li><a :href="telegram" target="_blank"><img src="../assets/images/telegram.gif" alt=""></a></li>
                        <!-- <li><a :href="telegrams" target="_blank"><img src="../assets/images/telegrams.gif" alt=""></a></li> -->
                        <!-- <li v-if="token == null"><a href="javascript:;" @click="FormLoginShow"><img src="../assets/images/sp.gif" alt=""></a></li>
                        <li v-else><a href="javascript:;" @click="showModalsp = true"><img src="../assets/images/sp.gif" alt=""></a></li> -->
                        <li class="icongame"><a :href="linkgame" target="_blank"><img src="../assets/images/game.gif" alt=""></a></li>
                    </ul>
                </div>
                <!-- end icon right -->
                <!-- modal chat -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModalsp" @click="showModalsp = false"></div>
                    <div class="modal" v-if="showModalsp">
                        <div class="wp-modal-login wp-modal-support">
                            <div class="header-modal">
                                <ul class="list_menu_modal">
                                    <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                    <li class="active">Trợ giúp</li>
                                </ul>
                                <button class="close-modal_login" @click="showModalsp = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <form action="" method="post" id="frm_chat" @submit="sendSupport">
                                        <div>
                                            <Select2 v-model="topic" :options="listtopic" placeholder="Chọn tiêu đề">
                                            </Select2>
                                        </div>
                                        <!-- <select v-model="topic">
                                            <option value="" selected>Chọn tiêu đề</option>
                                            <option :value="item.id" v-for="(item, index) in listtopic" :key="index">{{ item.title }}</option>
                                        </select> -->
                                        <input type="text" v-model="titlesp" placeholder="Tiêu đề">
                                        <input type="text" v-model="content" placeholder="Nội dung">
                                        <button type="submit">Gửi</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal chat -->
                <!-- modal Notifi -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModal_notifi" @click="showModal_notifi = false"></div>
                    <div class="modal" v-if="showModal_notifi">
                        <div class="wp-modal-login">
                            <div class="header-modal">
                                <ul class="list_menu_modal">
                                    <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                    <li class="active">Thông báo</li>
                                </ul>
                                <button class="close-modal_login" @click="showModal_notifi = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <p class="text_notifi">
                                        Đã đến thời gian phát quà, vui lòng bấm 
                                        <a :href="link_webs + '/gift/' + iduser" target="_blank">Vào đây</a>
                                        <!-- <router-link v-bind:to="{ name: 'Gift_nm', params: { id: iduser } }">Vào đây</router-link> -->
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal Notifi -->
                <!-- modal cash in -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showmodal_cashin" @click="showmodal_cashin = false"></div>
                    <div class="modal" v-if="showmodal_cashin">
                        <div class="wp-modal-login">
                            <div class="header-modal">
                                <ul class="list_menu_modal">
                                    <li class="img_modal"><img src="../assets/images/logo.png"></li>
                                    <li class="active">Nạp tiền</li>
                                </ul>
                                <button class="close-modal_login" @click="showmodal_cashin = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <form action="" method="post" id="frm_uploads_img" @submit="cashinFrm">
                                        <input v-numeric-input="{ thousands: ',', align: 'left'}" id="pointcash" placeholder="Số tiền (*)" />
                                        <input type="text" class="accountf8" v-model="accountf8" placeholder="Tài khoản f8bet (*)" />
                                        <input type="file" class="change_picture" name="cash_picture" id="cash_picture" />
                                        <label class="cash_picture" for="cash_picture">
                                            <font-awesome-icon icon="fa-solid fa-image" />
                                            <span>Chọn ảnh</span>
                                        </label>
                                        <textarea v-model="note" id="notecash" cols="30" rows="3" placeholder="Ghi chú"></textarea>
                                        <button type="submit">Nạp tiền</button>
                                        <!-- Tỷ lệ quy đổi: xu <span>{{ amount_change }}</span>, điểm <span>{{ point_change }}</span><br /> -->
                                        <!-- , <span>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(pointcode) }}</span> điểm -->
                                        <p class="text_ratio">Nạp <span>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(min_credit) }}</span> nhận được <span>{{ xucode }}</span> xu</p>
                                        <p class="title_ratio">
                                            Nạp tối thiểu <span>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(min_credit) }}</span>, 
                                            tối đa <span>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(max_credit) }}</span> trong ngày
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal cash in -->
                <!-- modal noti 50k -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="modal_noti50" @click="modal_noti50 = false"></div>
                    <div class="modal" v-if="modal_noti50">
                        <div class="wp-modal50k">
                            <div class="header-modal">
                                <button class="close-modal50k" @click="modal_noti50 = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <img src="../assets/images/popup.gif" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal noti 50k -->
            </div>
        </header>
        <CurvedBottomNavigation :options="options" v-model="selected" background-color='#fff' foreground-color='#FF42E6' icon-color='#C01BA0'>
            <template #path="{ props }">
                <b>{{ props.path }}</b>
            </template>
        </CurvedBottomNavigation>
        <div id="loading_show" class="loading_show" style="position: fixed; left: 0; bottom: 0; top: 0%; right: 0%; text-align: center; background: rgba(0,0,0,.5); z-index: 999999999;">
            <div class="windows8"> 
                <div class="wBall" id="wBall_1"> 
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_2">
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_3">
                    <div class="wInnerBall"></div>
                </div> 
                <div class="wBall" id="wBall_4">
                    <div class="wInnerBall"></div> 
                </div>
                <div class="wBall" id="wBall_5"> 
                    <div class="wInnerBall"></div>
                </div>
            </div>
        </div>

        <div class="container ner_responesize Vue3Marquee_dk">
            <div class="marquee_content_dk">
                <div class="img_loa">
                    <img src="../assets/images/loa.png" alt="">
                    <span>Thông báo</span>
                </div>
                <div class="content_mquee_home">
                    <Vue3Marquee :pause-on-click="true" duration="100">
                        <ul>
                            <li><a :href="linkf8bet" target="_blank">🧧🧧🧧THÔNG BÁO: Quý khách đăng ký tài khoản tại F8LIVE, Đồng thời có thể đăng nhập 78WIN,và trãi nghiệm các trò chơi hấp dẫn, Thành viên mới nhận được 58K Free, Thưởng 100% lần đầu, Nạp thêm thưởng lên đến 128,000K, Đăng nhập thưởng xuyên để nhận hồng bao 78WIN, xin cảm ơn quý khách!</a></li>
                        </ul>
                    </Vue3Marquee>
                </div>
            </div>
        </div>






        <div class="imgchat_bottom">
            <img src="../assets/images/chat1.gif" alt="" @click="showChat">
        </div>
        <!-- chat -->
		<div class="root_chat_vaohang" v-if="token != null">
            <div class="wp_chat_vaohang">
                <div class="list_chat_message">
                    <div class="info_idol_chat info_idol_chatnoneid_s">
                        <h2>
                            <img src="../assets/images/chat1.gif" alt="">
                            <!-- <img :src="link_webchats + 'images/user_avatar/' + imgGr" alt=""> -->
                            <span>{{ nameGr }}</span>
                        </h2>
                        <img src="../assets/images/close_md.png" alt="" class="closechat_vaohang" @click="closeChat">
                    </div>
                    <div class="pinMessage">
                        <div class="contentPin" v-if="checkpin==1" @click="scrollPin(listPin.id)">
                            <div class="textpin">
                                <h4>Pin message {{listPin.author}}</h4>
                                <p v-if="listPin.image != null" class="imgpinchat"><img :src="link_webchats + listPin.image" alt=""></p>
                                <p v-else-if="listPin.file != null"><img src="../assets/images/file.png" alt="">{{ listPin.file_name }}</p>
                                <p v-else>{{ listPin.content }}</p>
                            </div>
                            <div class="delePin">
                                <img src="../assets/images/xoachat1.png" alt="" :data_id="listPin.id" @click="deletePinMes($event)">
                            </div>
                        </div>
                    </div>
                    <div class="list_chat_detail_mes" @scroll="scrollDataDetailMes" ref="scrollMess"> <!--  @scroll="scrollDataDetailMes" -->
                        <div class="item_chat_mes" :id="'itemMes'+item.id" v-for="(item, index) in listDetailMes" :key="index" :ref="'itemMes'+item.id">
                            <div :class="'leftidol_chat message_' + item.id" v-if="iduser != item.user_from">
                                <p v-if="isSameUsers(item, listDetailMes[index + 1])" class="userChat">{{ item.author }}</p>
                                <p v-else class="userChat" style="opacity: 0;">{{ item.author }}</p>
                                <div class="left_text_mes">
                                    <div class="avartaId" v-if="isSameUsers(item, listDetailMes[index + 1])">
                                        <img :src="link_web + item.avatar" alt="" v-if="item.avatar != null">
                                        <img src="../assets/images/user.png" alt="" v-else>
                                    </div>
                                    <div class="avartaId" v-else><img :src="link_web + item.avatar" alt="" style="opacity: 0;"></div>
                                    <div class="textMes">
                                        <div class="reply" v-if="item.author_reply != null">
                                            <a :data_id="item.id_reply" class="clickreplyrun" @click="scrollReply(item.id_reply)">
                                                <p class="authorrl">{{ item.author_reply }}</p>
                                                <p v-if="item.image_reply != null" class="imgshowrep"><img :src="link_webchats + item.image_reply" alt=""></p>
                                                <p v-else-if="item.file_name_reply != null"><img src="../assets/images/file.png" alt="">{{ item.file_name_reply }}</p>
                                                <p v-else class="pcontent-rep">{{ item.content_reply }}</p>
                                            </a>
                                        </div>
                                        <p v-if="item.image != null">
                                            <Fancybox>
                                                <a data-fancybox="" :href="link_webchats + item.image">
                                                    <img :src="link_webchats + item.image" alt="" class="imgchat_sh">
                                                </a>
                                            </Fancybox>
                                        </p>
                                        <p v-else-if="item.file != null"><a :href="link_webchats + item.file" target="_blank"><img src="../assets/images/file.png" alt="">{{ item.file_name }}</a></p>
                                        <p v-else-if="item.image_album != null" class="listImgchatshow">
                                            <span v-for="(item2, index2) in item.image_album.split(';')" :key="index2">
                                                <Fancybox>
                                                    <a data-fancybox="" :href="link_webchats + item2">
                                                        <img :src="link_webchats + item2" alt="">
                                                    </a>
                                                </Fancybox>
                                            </span>
                                        </p>
                                        <p v-else>{{ item.content }}</p>
                                        <span class="ngaytao_chat date_ngaytao" v-if="isSameUser(item, listDetailMes[index - 1])">{{ item.time + ' ' + item.date }}</span>
                                    </div>
                                    <div class="delete_mes_left">
                                        <img src="../assets/images/replys.png" alt="" :data_id="item.id" :data_name="item.author" :data_content="item.content" :data_img="item.image" :data_filename="item.file_name" class="replybtn" @click="repyChat($event)">
                                        <img src="../assets/images/pins.png" alt="" :data_id="item.id" class="pinbtn" @click="pinMes($event)">
                                    </div>
                                </div>
                            </div>
                            <div :class="'right_user_chat message_' + item.id" v-else>
                                <p v-if="isSameUsers(item, listDetailMes[index + 1])" class="userChat">{{ item.author }}</p>
                                <p v-else class="userChat" style="opacity: 0;">{{ item.author }}</p>
                                <div class="right_text_mes">
                                    <div class="delete_mes_right">
                                        <img src="../assets/images/xoachats.png" alt="" :data_id="item.id" @click="deleteMes($event)">
                                        <img src="../assets/images/replys.png" alt="" :data_id="item.id" :data_name="item.author" :data_content="item.content" :data_img="item.image" :data_filename="item.file_name" class="replybtn" @click="repyChat($event)">
                                        <img src="../assets/images/pins.png" alt="" :data_id="item.id" class="pinbtn" @click="pinMes($event)">
                                    </div>
                                    <div class="textMes">
                                        <div class="reply" v-if="item.author_reply != null">
                                            <a :data_id="item.id_reply" class="clickreplyrun" @click="scrollReply(item.id_reply)">
                                                <p class="authorrl" :data_id="item.id_reply">{{ item.author_reply }}</p>
                                                <p v-if="item.image_reply != null" class="imgshowrep" :data_id="item.id_reply"><img :src="link_webchats + item.image_reply" alt="" :data_id="item.id_reply"></p>
                                                <p v-else-if="item.file_name_reply != null" :data_id="item.id_reply"><img src="../assets/images/file.png" alt="" :data_id="item.id_reply">{{ item.file_name_reply }}</p>
                                                <p v-else class="pcontent-rep" :data_id="item.id_reply">{{ item.content_reply }}</p>
                                            </a>
                                        </div>
                                        <p v-if="item.image != null">
                                            <Fancybox>
                                                <a data-fancybox="" :href="link_webchats + item.image">
                                                    <img :src="link_webchats + item.image" alt="" class="imgchat_sh">
                                                </a>
                                            </Fancybox>
                                        </p>
                                        <p v-else-if="item.file != null"><a :href="link_webchats + item.file" target="_blank"><img src="../assets/images/file.png" alt="">{{ item.file_name }}</a></p>
                                        <p v-else-if="item.image_album != null" class="listImgchatshow">
                                            <span v-for="(item2, index2) in item.image_album.split(';')" :key="index2">
                                                <Fancybox>
                                                    <a data-fancybox="" :href="link_webchats + item2">
                                                        <img :src="link_webchats + item2" alt="">
                                                    </a>
                                                </Fancybox>
                                            </span>
                                        </p>
                                        <p v-else>{{ item.content }}</p>
                                        <span class="ngaytao_chat date_ngaytao" v-if="isSameUser(item, listDetailMes[index - 1])">{{ item.time + ' ' + item.date }}</span>
                                    </div>
                                    <div class="avartaId" v-if="isSameUsers(item, listDetailMes[index + 1])">
                                        <img :src="link_web + item.avatar" alt="" v-if="item.avatar != null">
                                        <img src="../assets/images/user.png" alt="" v-else>
                                    </div>
                                    <div class="avartaId" v-else><img :src="link_web + item.avatar" alt="" style="opacity: 0;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_chat_mes">
                    <div class="replyMes_show" v-if="typeSend=='reply'">
                        <div class="repMes">
                            <p class="titlerep">Trả lời {{ namerl }}</p>
                            <p v-if="imgrl != null" class="imgrepshow"><img :src="link_webchats + imgrl" alt=""></p>
                            <p v-else-if="filerl != null">{{ filerl }}</p>
                            <p v-else>{{ contentrl }}</p>
                        </div>
                        <img src="../assets/images/closegf.png" alt="" class="imgclose_reply" @click="closeReply">
                    </div>
                    <form action="" method="post" @submit="sendChatMes">
                        <div class="wp_img_text_mes_chat">
                            <div class="pic_file_mes">
                                <div class="picchat_div">
                                    <input type="file" id="imagechat" class="imagechat" accept="image/*" @change="submitImgMes($event)" multiple />
                                    <label class="lbimagechat" for="imagechat">
                                        <img src="../assets/images/imgs.png" alt="">
                                    </label>
                                </div>
                                <div class="filechat_div">
                                    <input type="file" id="filechat" class="filechat" @change="submitFileMes($event)" />
                                    <label class="lbfilechat" for="filechat">
                                        <img src="../assets/images/fl.png" alt="">
                                    </label>
                                </div>
                            </div>
                            <div class="wp_inputchat_mes">
                                <input type="text" id="textmeschat" autocomplete="off" value="" class="inputchat_mes">
                                <div class="stickermes">
                                    <img src="../assets/images/icon.png" alt="" class="show_list_icon" @click="showIconChatMes">
                                </div>
                                <div class="btn_sb_mes">
                                    <button type="submit" id="submitchatMes"></button>
                                </div>
                            </div>
                            <div class="liststickermes">
                                <div class="item_icon_chat_mes">
                                    <p>
                                        <img :data_img="item.url" :src="link_webchats + item.url" alt="" v-for="(item, index) in listIcon" v-bind:key="index" class="icon_chat_send" @click="sendIconChatMes">
                                    </p>
                                </div>
                            </div>
                            <input type="hidden" name="data_img" class="sticker_chatMes">
                        </div>
                    </form>
                </div>
            </div>
            <input type="hidden" class="ididol_hd" value="1">
            <input type="hidden" class="idiUser_mes" :value="iduser">
        </div>
		<!-- end chat -->


    </div>
</template>

<script>
import {reactive} from "vue"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import SocketIO from 'socket.io-client'
import Select2 from 'vue3-select2-component';

import $ from 'jquery'
import Swal from 'sweetalert2'

import Login_f8 from '@/components/Login'
import Register_f8 from '@/components/Register'

$( document ).ready(function() {
    $(document).on('click', '.fogotpass', function() {
        $('#frm_login').hide();
        $('#frm_forgot').show();
    });
    $(document).on('click', '.login_back', function() {
        $('#frm_forgot').hide();
        $('#frm_login').show();
    });
    $(document).on('click', '.clickshow', function() {
        $('.listnotiHeader').slideToggle();
    });
    $(document).on('click', '.scrollNoti a', function() {
        $('.listnotiHeader').slideUp();
    });





    $(document).on('click', '.item_idol_chat', function() {
        $('.item_idol_chat').removeClass('active');
        $(this).addClass('active');
    });
    var domainWeb = 'https://f8game.click/';
    var socket = SocketIO(domainWeb,{ transports: ['websocket', 'polling', 'flashsocket'] });
    var imgxoapin = require('@/assets/images/xoachat1.png')
    var imgfile = require('@/assets/images/file.png')
    
    socket.on('chat:delete',function(result){
        $(`#itemMes${result.id}`).remove()
    })

    $(document).on('click', '.deleChatMes', function() {
        let idmes = $(this).attr('data_id');
        let tokenchat = window.localStorage.getItem('token')
        $.ajax({
            method: 'POST',
            url: 'https://f8game.click/api/chat-group/delete-message',
            headers: {
                'Authorization':'Bearer ' + tokenchat,
            },
            data:{
                id: idmes,
            }
        });
    });

    $(document).on('click', '.clickreplyrun_ss', function() {
        let id = $(this).attr('data_id')
        let div = $(this).parents('.list_chat_detail_mes');
        if (typeof div.find("#itemMes"+id).offset() !== 'undefined' ) {
            div.animate({scrollTop: div.scrollTop() + (div.find("#itemMes"+id).offset().top - div.offset().top - 120)}, 300);
        }
    });

    socket.on('chat:pin_message',function(result){
        // console.log('111', result);
        // let ididol = $('.ididol_hd').val();
        // let iduser = $('.idiUser_mes').val();
        if(result.message.pin == 1){
            let strpin = '';
            strpin = `<div class="contentPin">
                        <div class="textpin clickRunPin" data_id="${result.message.id}">
                            <h4>Pin message ${result.message.author}</h4>`
                            if(result.message.image != null){
                                strpin += `<p class="imgpinchat"><img src="${domainWeb}${result.message.image}" alt=""></p>`
                            }else if(result.message.file != null){
                                strpin += `<p ><img src="${imgfile}" alt="">${result.message.file_name}</p>`
                            }else if(result.message.image_album != null) {
                                // 
                            }else{
                                strpin += `<p v-else>${result.message.content}</p>`
                            }
                            
                        strpin += `</div>
                        <div class="delePin">
                            <img src="${imgxoapin}" alt="" data_id="${result.message.id}" class="deletePinNew">
                        </div>
                    </div>`
            // if((ididol == result.message.user_to || ididol == result.message.user_from) && (iduser == result.message.user_to || iduser == result.message.user_from)){
                $(`.pinMessage`).html(strpin);
            // }
        }
    })

    $(document).on('click', '.clickRunPin', function() {
        let id = $(this).attr('data_id')
        let div = $('.list_chat_detail_mes');
        if (typeof div.find("#itemMes"+id).offset() !== 'undefined' ) {
            div.animate({scrollTop: div.scrollTop() + (div.find("#itemMes"+id).offset().top - div.offset().top - 120)}, 300);
        }
    });

    $(document).on('click', '.deletePinNew', function() {
        $(`.contentPin`).remove()
        let idmes = $(this).attr('data_id');
        let tokenchat = window.localStorage.getItem('token')
        $.ajax({
            method: 'POST',
            url: 'https://f8game.click/api/chat-group/delete-pin-message',
            headers: {
                'Authorization':'Bearer ' + tokenchat,
            },
            data:{
                id: idmes,
            }
        });
    });
});


export default {
    name: 'Header_live',
    setup() {
        const data = reactive({
            description: '',
        })
        return {
            data,
            editor: ClassicEditor
        }
    },
    data () {
        return {
            active: '',
            showModal: false,
            showModal_dk: false,
            showModalsp: false,
            showModals: false,
            showModal_bgr: false,
            showmodal_changepass: false,
            modal_noti50: false,
            tabSelecteds: 'login',
            token: window.localStorage.getItem('token'),
            timeToken: window.localStorage.getItem('time_token'),
            rank: window.localStorage.getItem('rank'),
            type_live: window.localStorage.getItem('type_live'),
            typeus: window.localStorage.getItem('typeus'),
            fullname: '',
            stream: 1,
            live: '',
            type: '',
            picture: null,
            title: '',
            resNull: '',
            password_old: '',
            password_new: '',
            password_confirm_change: '',
            email_forgot: '',
            message: '',
            iduser: 0,
            listbanca: [],
            listnohu: [],
            listgame: [],
            editorConfig: {
                toolbar: [ 'link', 'italic', 'bold'],
            },
            orther: '',
            numberspo: '',
            listtopic: [],
            topic: '',
            titlesp: '',
            content: '',
            classHeader: '',
            selected: 1,
            options: [
                { id: 1, icon: "falive_icon", title: "Chơi game", path: { name: "Games_nm" } },
                { id: 2, icon: "fasupport", title: "Live", path: { name: "LiveBg_nm" } },
                { id: 3, icon: "fahome_icon", title: "Home", path: { name: "Home_live" } },
                // { id: 3, icon: "falive18_icon", title: "Show 18+", path: { name: "IdolLive_nm" } },
                { id: 5, icon: "favideo", title: "Video", path: { name: "Video_nm" } },
                { id: 4, icon: "falive18_icon", title: "Hỗ trợ", path: { name: "Contact_nm" } },
            ],
            showModal_notifi: false,
            facebook: '',
            telegram: '',
            telegrams: '',
            linkgame: '',
            zalo: '',
            showmodal_cashin: false,
            point: '',
            accountf8: '',
            note: '',
            amount_change: '',
            point_change: '',
            min_credit: '',
            max_credit: '',
            pointcode: '',
            xucode: '',
            linkf8bet: '',
            phone: '',
            listNoti: [],
            numberNoti: '',

            token1: window.localStorage.getItem('token'),
            nameGr: '',
            imgGr: '',
            listIdolchat: [],
            dateEnd: '',
            listType: [],
            iduserPost: [],
            listDetailMes: [],
            nameId: '',
            picId: '',
            idIdol: '',
            listIcon: [],
            idMesEnd: '',
            typesticker: '',
            other: '',
            nameidol: '',
            imgIdol: '',
            itemData: [],
            typeSend: 'insert',
            namerl: '',
            contentrl: '',
            imgrl: '',
            filerl: '',
            idreply: '',
            listPin: [],
            checkpin: 0,
        }
    },
    components: {
        Select2,
        Login_f8,
        Register_f8,
    },
    created () {
        this.getInfoLink()
        this.getRatiocash()
        if(this.token !== null){
            this.getListInfo()
            // this.resettoken()
            this.getListspo()
            setInterval(this.getListspo, 60000)
            // setInterval(this.logoutAuto, 3600000)
            this.logoutMinutes()
            this.getListtopic()
            this.notiIdolGift()
            this.getListNoti()
            if(window.localStorage.getItem('first_password') == 0){
                this.showmodal_changepass = true
            }
            if(window.localStorage.getItem('last_login') == 0){
                this.modal_noti50 = true
            }
            this.getDataDetailMes()
            this.listIconChat()
            this.connectChat()
        }
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkgame = response.data.data.list_data.link_game;
                this.facebook = response.data.data.list_data.facebook
                this.telegram = response.data.data.list_data.telegram
                this.telegrams = response.data.data.list_data.telegram2
                this.zalo = response.data.data.list_data.zalo
                this.linkf8bet = response.data.data.list_data.banner
            })
        },
        logout (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_logout).then(res => {
                if (res.data.success === true) {
                    $('#loading_show').css('display', 'none')
                    localStorage.clear();
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    window.location.href = '/'
                }
            })
        },
        // resettoken () {
        //     if(window.localStorage.getItem('token') !== null){
        //         let timetk = window.localStorage.getItem('time_token')
        //         let today = new Date();
        //         let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
        //         let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        //         let timestamp = date + ' ' + time;
        //         let timereset = Math.round(+new Date(timestamp)/1000) - Math.round(+new Date(timetk)/1000)
        //         let result = timereset / 60
        //         if(result > 55){
        //             this.axios.get(this.api_resettoken, {
        //                 headers: {
        //                     Authorization: 'Bearer ' + window.localStorage.getItem('token')
        //                 }
        //             }).then((response) => {
        //                 window.localStorage.setItem('token', response.data.data)
        //                 let today = new Date();
        //                 let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
        //                 let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        //                 let timestamp = date + ' ' + time;
        //                 window.localStorage.setItem('time_token', timestamp)
        //             }).catch(e => {
        //                 if (e?.response?.status === 500 || e?.response?.status === 405) {
        //                     localStorage.clear();
        //                     window.location.href = '/'
        //                 }
        //             })
        //         }
        //     }
        // },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.iduser = response.data.data.user.id
                this.fullname = response.data.data.user.fullname
                this.live = response.data.data.user.live
                this.type = response.data.data.user.type
                this.picture = response.data.data.user.hinh_anh
            }).catch(e => {
                this.orther = e.response
                // if (e.response.status === 401) {}
                    // this.$swal({
                    //     position: 'top-end',
                    //     icon: 'error',
                    //     title: e.response.data.message,
                    //     showConfirmButton: false,
                    //     timer: 11000
                    // })
                    localStorage.clear();
                    setTimeout(function() {
                        this.axios.post(this.api_logout).then(res => {
                            this.resNull = res
                            localStorage.clear();
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 2000);
                // }
            })
        },
        clickMenu () {
            this.active = 'active'
        },
        clickRemove () {
            this.active = ''
        },
        changeTabs (tab) {
            this.tabSelecteds = tab
        },
        viewmodal_rg () {
            this.showModal_dk = true
            this.showModal = false
        },
        viewmodal_lg () {
            this.showModal = true
            this.showModal_dk = false
        },
        LiveStreamOn (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            // this.title = $('.title_live').val();
            // this.message = $('.title_live').val();
            this.axios.post(this.api_live, 
                {
                    live: this.stream,
                    tieu_de: this.title,
                    // message: this.message,
                    device: 1
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.href = '/'
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        LiveStreamOff (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_live, 
                {
                    live: 0,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                // console.log('res', res);
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.href = '/'
            }).catch(e => {
                // console.log('e', e);
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        ChangePicture (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.showModal = false
            this.showModals = false
            let file_img = document.getElementById('change_picture').files[0]
            const form = new FormData();
            form.append('background', file_img);
            this.axios.post(this.api_change_bgr, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                window.location.href = '/'
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        notiRank () {
            this.$swal({
                position: 'top-end',
                icon: 'error',
                title: 'Bạn không đủ level, vui lòng liên hệ quản trị viên',
                showConfirmButton: false,
                timer: 3000
            })
        },
        Changepass (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_changepass, 
            {
                password: this.password_old,
                password_new: this.password_new,
                password_confirm: this.password_confirm_change,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then(res => {
                this.showmodal_changepass = false
                // console.log(res.data.data.token);
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                localStorage.clear();
                setTimeout(function(){ window.location.href = '/' }, 4000);
            }).catch(e => {
                // console.log(e);
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        forgotpassword (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.showModal = false
            this.axios.post(this.api_forgot, {
                email: this.email_forgot,
            }).then(res => {
                // console.log(res);
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                // window.location.href = '/'
            }).catch(e => {
                // console.log(e);
                $('#loading_show').css('display', 'none')
                if(e.response.status === 404){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        getListspo () {
            this.axios.get(this.api_listchatsp, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.numberspo = response.data.data.filter(d => d.status == 1)
            }).catch(e => {
                if(e.response.status === 401){
                    this.axios.post(this.api_logout).then(res => {
                        if (res.data.success === true) {
                            localStorage.clear();
                            this.$swal({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Kết thúc phiên đăng nhập',
                                showConfirmButton: false,
                                timer: 4000
                            })
                            setTimeout(() => {
                                window.location.href = '/'
                            }, 3000);
                        }
                    })
                }
            })
        },
        getListtopic () {
            this.axios.get(this.api_listtopic).then((response) => {
                let listtopic = response.data.data
                let datatp = []
                listtopic.forEach(item => {
                    datatp.push({...item, text: item.title});
                });
                this.listtopic = datatp
            })
        },
        sendSupport (e) {
            e.preventDefault();
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_sendSupport, 
                {
                    topic_id: this.topic,
                    title: this.titlesp,
                    content: this.content,
                    support_id: 0
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                this.showModal = false,
                window.location.href = '/support'
                this.topic_id = this.title = this.content = ''
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        FormLoginShow () {
            this.showModal = true
            this.tabSelecteds = 'login'
            this.active = ''
            this.checkClick = 1
        },
        handleScroll () {
            if (window.scrollY > 100) {
                this.classHeader = 'active'
            }else{
                this.classHeader = ''
            }
        },
        notiIdolGift () {
            var thispage = this
            var idUserIdol = window.localStorage.getItem('numberus')
            var socket = SocketIO(this.link_web,{ transports: ['websocket', 'polling', 'flashsocket'] });
            socket.on('gift:alarm',function(result){
                // console.log('abc socket', result.data.idol_id);
                if(idUserIdol == result.data.idol_id){
                    thispage.showModal_notifi = true
                }
            });
        },
        showModalCashin () {
            this.showmodal_cashin = true
        },
        cashinFrm (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let file_img = document.getElementById('cash_picture').files[0]
            let point_money = $('#pointcash').val()
            if(file_img == undefined){
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Vui lòng nhập hình ảnh',
                    showConfirmButton: false,
                    timer: 4000
                })
            }else{
                const form = new FormData();
                form.append('point', point_money);
                form.append('hinh_anh', file_img);
                form.append('account_f8bet', this.accountf8);
                form.append('note', this.note);
                this.axios.post(this.api_cashin, 
                    form,
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token')
                        }
                    }
                ).then(res => {
                    this.showmodal_cashin = false
                    $('#pointcash').val('')
                    this.accountf8 = this.note = this.point = ''
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    // window.location.href = '/'
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    if(e.response.status === 404){
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                })
            }
        },
        getRatiocash () {
            this.axios.get(this.api_ratiocash).then(res => {
                this.amount_change = res.data.data.amount_change
                this.point_change = res.data.data.point_change
                this.min_credit = res.data.data.min_credit
                this.max_credit = res.data.data.max_credit
                this.pointcode = this.min_credit * this.point_change
                this.xucode = this.min_credit * this.amount_change
            })
        },
        reLoadPage () {
            if(window.localStorage.getItem('first_password') == 0){
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Vui lòng thay đổi mật khẩu',
                    showConfirmButton: false,
                    timer: 3000
                })
            }else{
                this.showmodal_changepass = false
                window.localStorage.setItem('first_password', 1)
            }
        },
        logoutAuto () {
            this.axios.post(this.api_logout).then(res => {
                if (res.data.success === true) {
                    localStorage.clear();
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Kết thúc phiên đăng nhập',
                        showConfirmButton: false,
                        timer: 4000
                    })
                    window.location.href = '/'
                }
            })
        },
        logoutMinutes () {
            let today = new Date();
            let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            let timestamp = date + ' ' + time;
            var d = new Date(timestamp.replace(/-/g, "/"));
            var d1 = new Date(this.timeToken.replace(/-/g, "/"));
            let timereset = Math.round(+d/1000) - Math.round(+d1/1000)
            // let timereset = Math.round(+new Date(timestamp)/1000) - Math.round(+new Date(this.timeToken)/1000)
            let result = timereset / 60
            if(result > 120){
                this.axios.post(this.api_logout).then(res => {
                    if (res.data.success === true) {
                        localStorage.clear();
                        this.$swal({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Kết thúc phiên đăng nhập',
                            showConfirmButton: false,
                            timer: 4000
                        })
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 3000);
                    }
                })
            }
        },
        formatDate (date) {
            let datePart = date.match(/\d+/g)
            let year = datePart[2].substring(0)
            let month = datePart[1]
            let day = datePart[0]
            return datePart[3] + ':' + datePart[4] + ' ' + year + '-' + month + '-' + day;
        },
        getListNoti () {
            this.axios.get(this.api_listNoti,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                let listNoti = response.data.data.notifications
                this.numberNoti = response.data.data.num_notifi
                const arr = [];
                listNoti.forEach(item => {
                    let arrinfo = JSON.parse(item.content)
                    arr.push({...item, arrinfo});
                });
                setTimeout(() => {
                    this.listNoti = arr;
                }, 800);
            })
        },
        viewNoti (event) {
            let idNoti = event.target.getAttribute('data_id')
            this.axios.post(this.api_viewNoti,
                {
                    id: idNoti
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                let listNoti = response.data.data.notifications
                this.numberNoti = response.data.data.num_notifi
                const arr = [];
                listNoti.forEach(item => {
                    let arrinfo = JSON.parse(item.content)
                    arr.push({...item, arrinfo});
                });
                setTimeout(() => {
                    this.listNoti = arr;
                }, 800);
            })
        },
        showModalrg () {
            this.showModal = false
            this.showModal_dk = true
        },
        showModallg () {
            this.showModal = true
            this.showModal_dk = false
        },

        
        showChat () {
            if(this.token == null){
                this.showModal = true
            }else{
                $('.img_chatgroup').addClass('hideclick')
                $('.root_chat_vaohang').show()
                var hg = $('.list_chat_detail_mes').prop('scrollHeight');
                $('.list_chat_detail_mes').animate({ scrollTop: hg+100+'px' }, 5);
            }
        },
        closeChat () {
            $('.img_chatgroup').removeClass('hideclick')
            $('.root_chat_vaohang').hide()
        },
        getDataDetailMes () {
            this.axios.get(this.api_detailMes,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                if(response.data.data.pin != null){
                    this.checkpin = 1
                    this.listPin = response.data.data.pin
                }else{
                    this.checkpin = 0
                    this.listPin = []
                }
                this.nameGr = response.data.data.group.name
                this.imgGr = response.data.data.group.avatar
                if (response.data.data.data.length > 0) {
                    this.listDetailMes = response.data.data.data.reverse()
                    this.idMesEnd = this.listDetailMes[0].id
                }else{
                    this.listDetailMes = []
                }
                // setTimeout(() => {
                //     var hg = $('.list_chat_detail_mes').prop('scrollHeight');
                //     $('.list_chat_detail_mes').animate({ scrollTop: hg+100+'px' }, 5);
                // }, 1500);
            })
        },
        scrollDataDetailMes () {
            this.$nextTick(function() {
                var hg = this.$refs.scrollMess;
                let heightScroll = hg.scrollTop
                if (heightScroll == 0 ) {
                    this.axios.get(this.api_detailMes + '?type_message=top&id=' + this.idMesEnd,
                        {
                            headers: {
                                Authorization: 'Bearer ' + this.token1
                            }
                        }
                    ).then((response) => {
                        let dataAr = response.data.data.data.reverse()
                        if(dataAr.length > 0) {
                            this.listDetailMes = dataAr.concat(this.listDetailMes)
                            this.idMesEnd = this.listDetailMes[0].id
                        }
                    })
                }
            });
        },
        scrollReply (id) {
            let divParent = this.$refs.scrollMess // div tổng
            let divChild = this.$refs['itemMes'+id]
            if (typeof divChild !== 'undefined' ) {
                divParent.scrollTo({
                    top: (divChild[0].offsetTop - divParent.offsetTop - 110),
                    behavior: 'smooth'
                })
            }else{
                this.axios.get(this.api_detailMes + '?user_to=1&id=' + id + '&type_message=load&type_group=0',
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token1
                        }
                    }
                ).then((response) => {
                    let dataAr = response.data.data.data.reverse()
                    if(dataAr.length > 0) {
                        this.listDetailMes = dataAr.concat(this.listDetailMes)
                        this.idMesEnd = this.listDetailMes[0].id
                        setTimeout(() => {
                            let divChilds = this.$refs['itemMes'+id]
                            if (typeof divChilds !== 'undefined' ) {
                                divParent.scrollTo({
                                    top: (divChilds[0].offsetTop - divParent.offsetTop - 110),
                                    behavior: 'smooth'
                                }) 
                            }
                        }, 500);
                    }
                })
            }
        },
        scrollPin (id) {
            let divParent = this.$refs.scrollMess // div tổng
            let divChild = this.$refs['itemMes'+id]
            if (typeof divChild !== 'undefined' ) {
                divParent.scrollTo({
                    top: (divChild[0].offsetTop - divParent.offsetTop - 110),
                    behavior: 'smooth'
                })
            }else{
                this.axios.get(this.api_detailMes + '?user_to=1&id=' + id + '&type_message=load&type_group=0',
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token1
                        }
                    }
                ).then((response) => {
                    let dataAr = response.data.data.data.reverse()
                    if(dataAr.length > 0) {
                        this.listDetailMes = dataAr.concat(this.listDetailMes)
                        this.idMesEnd = this.listDetailMes[0].id
                        setTimeout(() => {
                            let divChilds = this.$refs['itemMes'+id]
                            if (typeof divChilds !== 'undefined' ) {
                                divParent.scrollTo({
                                    top: (divChilds[0].offsetTop - divParent.offsetTop - 110),
                                    behavior: 'smooth'
                                }) 
                            }
                        }, 500);
                    }
                })
            }
        },
        listIconChat (){
            // this.axios.get(this.api_listicon).then((response) => {
            //     this.listIcon = response.data.data
            // })
            this.axios.get(this.api_liststicker, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                } 
            }).then((response) => {
                this.listIcon = response.data.data.data_sticker
            })
        },
        showIconChatMes () {
            $('.liststickermes').slideToggle();
        },
        sendChatMes (e) {
            e.preventDefault();
            let mes = $('#textmeschat').val();
            let imgSticker = $('.sticker_chatMes').val()
            let idTo = $('.ididol_hd').val()

            if(imgSticker != ''){
                this.typesticker = 1
            }else{
                this.typesticker = ''
            }
            const form = new FormData();
            form.append('message', mes);
            form.append('upload_image_message', '');
            form.append('upload_image_album_message', '');
            form.append('content_image_album_message', '');
            form.append('upload_file_message', '');
            form.append('image_sticker', imgSticker);
            form.append('type_sticker', this.typesticker);
            form.append('user_from', idTo);
            form.append('type_action', this.typeSend);
            form.append('type_group', 0);
            if(this.typeSend == 'reply'){
                form.append('id_edit', this.idreply);
            }
            this.axios.post(this.api_sendMes, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                this.other = response
                $('#textmeschat').val('');
                $('.sticker_chatMes').val('');
                this.typeSend = 'insert'
            }).catch(e => {
                Swal.fire({
                    position: 'top-end',
                    showConfirmButton: false,
                    title: e.response.data.message,
                    icon: 'error',
                    timer: 2000
                });
            })
            return false;
        },
        sendIconChatMes (event) {
            var dataImg = event.target.getAttribute('data_img')

            let idTo = $('.ididol_hd').val()
            var curDate = new Date();
            var hours = curDate.getHours();
            var minutes = curDate.getMinutes();
            let minutess = ''
            if(minutes < 10){
                minutess = '0' + minutes;
            }else{
                minutess = minutes
            }
            const ampm = hours >= 12 ? "PM" : "AM";
            let hourMinutes = hours + ':' + minutess + ' ' + ampm
            this.itemData = this.listIdolchat.filter(d=>d.id == idTo)
            this.itemData.filter(item => item.id == idTo).map(function (obj) {
                obj.m_time = hourMinutes;
                return obj;
            });

            $('.sticker_chatMes').val(dataImg)
            $('#submitchatMes').trigger('click')
            $('.liststickermes').hide()
        },
        submitImgMes (event) {
            event.preventDefault();
            $('.loading_show').show().fadeIn(10)
            let imgLength = document.getElementById('imagechat').files.length
            let url_image = document.getElementById('imagechat').files[0]
            let arrImg = document.getElementById('imagechat').files
            
            let idTo = $('.ididol_hd').val()

            const form = new FormData();
            form.append('message', '');
            if(imgLength==1){
                form.append('upload_image_message', url_image);
                form.append('upload_image_album_message', '');
            }else{
                form.append('upload_image_message', '');
                for (let i = 0; i < imgLength; i++) {
                    form.append('upload_image_album_message[]', arrImg[i]);
                }
            }
            form.append('content_image_album_message', '');
            form.append('upload_file_message', '');
            form.append('image_sticker', '');
            form.append('type_sticker', '');
            form.append('user_from', idTo);
            form.append('type_action', this.typeSend);
            form.append('type_group', 0);

            this.axios.post(this.api_sendMes, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.other = response
                $("#imagechat").val('');
                this.typeSend = 'insert'
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        submitFileMes (event) {
            event.preventDefault();
            $('.loading_show').show().fadeIn(10)
            let url_file = document.getElementById('filechat').files[0]
            let idTo = $('.ididol_hd').val()

            const form = new FormData();
            form.append('message', '');
            form.append('upload_image_message', '');
            form.append('upload_image_album_message', '');
            form.append('content_image_album_message', '');
            form.append('upload_file_message', url_file);
            form.append('image_sticker', '');
            form.append('type_sticker', '');
            form.append('user_from', idTo);
            form.append('type_action', this.typeSend);
            form.append('type_group', 0);

            this.axios.post(this.api_sendMes, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                $('#loading_show').css('display', 'none')
                this.other = response
                $("#filechat").val('');
                this.typeSend = 'insert'
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        deleteMes (event) {
            let idMes = event.target.getAttribute('data_id')
            $('#itemMes'+idMes).remove()
            this.axios.post(this.api_deleteMes,
                {
                    id: idMes
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                this.other = response
            })
        },
        repyChat (event) {
            this.typeSend = 'reply'
            this.idreply = event.target.getAttribute('data_id')
            this.namerl = event.target.getAttribute('data_name')
            this.contentrl = event.target.getAttribute('data_content')
            this.imgrl = event.target.getAttribute('data_img')
            this.filerl = event.target.getAttribute('data_filename')
        },
        closeReply () {
            this.typeSend = 'insert'
        },
        pinMes (event) {
            event.preventDefault();
            var idmes = event.target.getAttribute('data_id')
            this.axios.post(this.api_pinMes, 
                {
                    id: idmes
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                this.checkpin = 1
                this.other = response
            }).catch(e => {
                this.checkpin = 0
                this.other = e
            })
            return false;
        },
        deletePinMes (event) {
            event.preventDefault();
            var idmes = event.target.getAttribute('data_id')
            this.axios.post(this.api_delepinMes, 
                {
                    id: idmes
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                this.other = response
                this.checkpin = 0
            }).catch(e => {
                this.other = e
            })
            return false;
        },
        formatDates (date) {
            let datePart = date.split('-')
            return datePart[2] + '-' + datePart[1] + '-' + datePart[0];
        },
        isSameUser (currentMessage, diffMessage) {
            var d = ''
            if(typeof diffMessage === 'undefined'){
                d = this.formatDates(currentMessage.date)
            }else{
                d = this.formatDates(diffMessage.date)
            }
            var d1 = this.formatDates(currentMessage.date)
            if(diffMessage && diffMessage.type === currentMessage.type && d == d1 && diffMessage.user_from === currentMessage.user_from){
                setTimeout(() => {
                    $('.message_' + diffMessage.id + ' .ngaytao_chat').addClass('removeDiv')
                }, 100);
            }
            return true;
        },
        isSameUsers (currentMessage, diffMessage) {
            var d = ''
            if(typeof diffMessage === 'undefined'){
                d = this.formatDates(currentMessage.date)
            }else{
                d = this.formatDates(diffMessage.date)
            }
            var d1 = this.formatDates(currentMessage.date)
            if(diffMessage && diffMessage.type === currentMessage.type && d == d1 && diffMessage.user_from === currentMessage.user_from){
                setTimeout(() => {
                    $('.message_' + diffMessage.id + ' .avartaId').addClass('removeDivImg')
                    $('.message_' + diffMessage.id + ' .userChat').addClass('removeDivImg')
                }, 100);
            }
            return true;
        },
        connectChat () {
            var thisSk = this
            var socket = SocketIO(this.link_webchats,{ transports: ['websocket', 'polling', 'flashsocket'] });
            socket.on('chat:message',function(result){
                let arrPush = {
                    action_edit: result.message.action_edit,
                    author_reply: result.message.author_reply,
                    content_reply: result.message.content_reply,
                    avatar: result.message.avatar,
                    content: result.message.content,
                    created_at: result.message.created_at,
                    date: result.message.date,
                    file: result.message.file,
                    file_audio: result.message.file_audio,
                    file_name: result.message.file_name,
                    id: result.message.id,
                    id_reply: result.message.id_reply,
                    id_select: result.message.id_select,
                    image: result.message.image,
                    image_album: result.message.image_album,
                    image_content: result.message.image_content,
                    time: result.message.time,
                    type: result.message.type,
                    type_call: result.message.type_call,
                    type_forward: result.message.type_forward,
                    type_group: result.message.type_group,
                    type_sticker: result.message.type_sticker,
                    updated_at: result.message.updated_at,
                    user_from: result.message.user_from,
                    user_to: result.message.user_to,
                }
                thisSk.listDetailMes = thisSk.listDetailMes.concat(arrPush)
                setTimeout(() => {
                    var hg = $('.list_chat_detail_mes').prop('scrollHeight');
                    $('.list_chat_detail_mes').animate({ scrollTop: hg+100 },500);
                }, 1000);
            });
        },
    }
}
</script>
<style></style>
